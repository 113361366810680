<template>
  <div class="bg--pattern rel black font-nunito about_section" :style="{ '--bgimg': bgImg }">
    <div class="bg--black"></div>
    <div class="bg--img"></div>
    <div class="rel">
      <VSheet class="content rel no-shadow" color="transparent" dark>
        <p class="subtitle">{{ content.subtitle }}</p>

        <VDivider color="white" class="about__divider"></VDivider>

        <p class="text mb-0" v-html="content.text"></p>

        <ArrowLink v-if="content.link || content.link_text" :href="content.link" color="background">{{ content.link_text }}</ArrowLink>
        <ScrollBtn v-if="content.showScroll && $vuetify.breakpoint.mdAndDown" @click="scrollTo('scroll-target-plans')" class="abs-r-b">
        </ScrollBtn>
      </VSheet>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { pixelRatio } from '../../../../utils/display';
import ArrowLink from "../atoms/ArrowLink.vue";
import ScrollBtn from "../atoms/ScrollBtn.vue";
import { scrollToElement } from '../../../../utils/helpers';

export default defineComponent({
  name: 'SectionAbout',

  components: {
    ArrowLink,
    ScrollBtn
  },

  props: {
    siteName: {
      type: String,
      default: "FlightScope Cloud"
    },
    content: {
      type: Object,
      default: () => ({
        subtitle: '',
        text: '',
        link_text: '',
        link: '',
      }),
    },
    sport: {
      type: String,
      default: process.env.VUE_APP_SPORT_TYPE.toLocaleLowerCase(),
    },
  },

  methods: {
    scrollTo(target) {
      scrollToElement(target);
    }
  },

  computed: {
    pixelRatio,
    breakpoint() {
      return this.$vuetify.breakpoint.lgAndUp ? 'lg' : this.$vuetify.breakpoint.mdAndUp ? 'md' : 'sm';
    },
    bgImg() {
      return `url(/img/tinified/${process.env.VUE_APP_SPORT_TYPE}/ball@${this.pixelRatio}x.png)`;
    }
  },
});
</script>

<style lang="scss" scoped>
@import 'vuetify/src/styles/styles.sass';

.content {
  padding: 53px 72px 53px 40px;
  position: relative;
}

.subtitle {
  color: var(--v-light-gray-base);
  font-size: em(20);
  margin-bottom: 5px;
}

.about__divider {
  margin-left: -40px;
  max-width: 123px;
}

.text {
  font-size: em(16);
  line-height: 21/14;
  margin-top: 22px;
  color: var(--v-light-gray-base);
}

.bg--pattern::before {
  @include bg-pattern;

  content: "";
  width: calc(100% - 76px + 16px);
  height: calc(100% + 37px);
  position: absolute;
  top: -37px;
  left: 76px;
}

.bg--black {
  content: '';
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
}

.bg--img {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: var(--bgimg);
  background-position: calc(100% + 3em) -4em;
  background-repeat: no-repeat;
  background-size: contain;
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .content {
    padding: 0
  }

  .subtitle {
    margin-left: 1em;
    margin-bottom: 71px;
  }


  .about__divider {
    margin-left: 1em;
    margin-bottom: 43px;
    max-width: 15em;
  }

  .text {
    font-size: em(20);
    max-width: 15em;
  }
  .content {
    padding: 83px;
    padding-top: 0;
  }

  .bg--pattern::before {
    top: 100px;
    left: -30px;
    width: 30px;
    height: 50%;
  }

  .bg--black {
    width: 200%;
  }

  .bg--img {
    width: 200%;
    background-position: 2em  15em;
    background-size: min(50vw, 30em);
  }
}
</style>
