import textCapitalize from '../../../filters/text_formatters'

export default {
  aboutSection: {
    subtitle: "About",
    text: "FlightScope is the global leader and pioneer in tracking and analyzing sports performance data. Renowned for accuracy and ease-of-use, FlightScope represents the pinnacle of tracking technology, enabling coaches and players to measure and analyze every aspect of performance with unmatched accuracy.",
    link_text: "",
    link: "",
    showScroll: false,
  },

  featuresSection: {
    systemBarHeight: 64,
    systemBarText: 'FS Cloud Features',
    color: '#27a9e0',
    content: [
      {
        get img() { return `/img/tinified/${process.env.VUE_APP_SPORT_TYPE}/section1.jpg`; },
        features: [
          'Video playback',
          'Pitching summary',
          'Pitch usage percentage',
          'Average velocity (mph)',
          'Average spin (rpm)',
          'Average horizontal movement (in)',
          'Average vertical movement (in)',
        ],
      },
      {
        get img() { return `/img/tinified/${process.env.VUE_APP_SPORT_TYPE}/section2.jpg`; },
        features: [
          'Strikeout percentage',
          'Swings and misses',
          'Swing percentage',
          'Whiff percentage',
          'Swinging strike percentage',
          'Zone percentage',
          'Z swing percentage',
        ],
      },
      {
        get img() { return `/img/tinified/${process.env.VUE_APP_SPORT_TYPE}/section3.jpg`; },
        features: [
          'Z contact percentage',
          'O swing percentage',
          'O contact percentage',
          'Export CSV*',
          'Share pitching and hitting video clips to Twitter',
          'Hitting and pitching charts including release height, extension, pitch location, pitch movement, and spray charts'
        ],
        postscriptum: '* Must purchase FS Cloud to export CSV data.'
      },
    ],
  },

  parallaxSection: {
    get img() { return (breakpoint = 'sm', pixelRatio = 1, tinified = false) => `/img/${tinified?'tinified/':''}${process.env.VUE_APP_SPORT_TYPE}/parallax-${breakpoint}@${pixelRatio}x.jpg`; },
  },

  plansSection: {
    title: 'Cloud storage memberships',
    plans: [
      {
        darkSystemBar: true,
        systemBarText: 'Free',
        color: '#27a9e0',
        features: [
          'View player profile',
          'Top fastball velocity',
          'Top exit velocity',
        ],
        action: 'register',
        actionText: 'Register for FREE'
      },
      {
        darkSystemBar: true,
        wide: true,
        systemBarText: 'Colleges and Academies',
        color: '#d2bd00',
        features: [
          'Strike Zone Heat Map',
          'Release Point Heat Map',
          'Extension Heat Map',
          'Pitch Movement Heat Map',
          'Pitcher Performance Tracking',
          'Share pitching and hitting video clips to Twitter',
          'Historical performance data',
          'Pitching Summary',
          'Pitch Location Heat Map',
          'Hitting and Pitching videos',
          'Batting Summary and Spray Charts',
          'Batting Performance Tracking',
          'View player profile',
          'Top fastball velocity',
          'Top exit velocity',
          'Track any Player',
          'Detailed pitching and batting reports'
        ],
        action: 'contact',
        actionText: 'Contact Us'
      },
    ],
  },

  siteName: 'FlightScope Cloud DOCKER',

  topSection: {
    subtitle: "Performance Data You Can Trust",
    title_1: "Merge the power of the <strong>FlightScope&nbsp;X3B</strong> with <strong>FS Cloud</strong>",
    get title_2() {return `${textCapitalize(process.env.VUE_APP_SPORT_TYPE)} Data Cloud` },
    text: "To <strong>analyze custom reports</strong> and detailed statistics from all your bullpens, batting practices, and games.",
    get text2() { return `Practice with a purpose. Enhance the data tracking capabilities of the FlightScope X3B to gain access to advanced game reports and player performance tracking not offered in the FS ${textCapitalize(process.env.VUE_APP_SPORT_TYPE)} Application.`;},
    link_text: "See our plans"
  },
}
