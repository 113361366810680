import { useDevicePixelRatio } from '@vueuse/core';

const pixelRatio  = () => {
  const { pixelRatio } = useDevicePixelRatio();
  return Math.min(3, Math.round(pixelRatio.value));
};

export {
  pixelRatio,
}
