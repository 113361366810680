<template>
  <v-card flat tile class="font-nunito">
    <VSystemBar class="bar justify-center text-uppercase" :height="systemBarHeight" :dark="darkSystemBar" :color="color">{{ systemBarText }}</VSystemBar>

    <div v-for="(section, i) in content" :key="i" class="feats" :class="{ 'd-flex': $vuetify.breakpoint.mdAndUp }">
      <div class="list">
        <ul>
          <li v-for="(feat,j) in section.features" :key="`${feat}-${j}`">
            <VIcon color="primary" class="mr-3">mdi-arrow-right</VIcon>
            {{ feat }}
          </li>
        </ul>
        <div v-if="section.postscriptum" class="font-italic mt-8">{{ section.postscriptum }}</div>
      </div>
      <v-img :src="section.img" contain/>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SessionCloudFeatures',

  props: {
    systemBarHeight: {
      type: Number,
      default: 64,
    },
    darkSystemBar: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'transparent',
    },
    systemBarText: {
      type: String,
      default: '',
    },
    content: {
      type: Array,
      default: () => [{
        image: '',
        features: ['Feature 1', 'Feature 2', 'Feature 3'],
      }],
    },
  },
});
</script>

<style lang="scss" scoped>
.theme--dark.v-system-bar {
  color: white;
}
.v-system-bar {
  font-size: em(20);
  font-weight: 700;
  letter-spacing: 1.7px;
}

p {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

.list {
  padding-top: em(36);
  padding-bottom: em(36);
  padding-left: em(28);
}

ul {
  padding-left: 0;
  list-style: none;
  min-width: 22em;
  max-width: 40em;
}

li {
  display: flex;
  font-size: em(16);
  text-transform: uppercase;
  color: var(--v-mineshaft2-base);

  & + li {
    margin-top: 1em;
  }
}

.feats.d-flex {
  justify-content: space-between;
  + .feats {
    margin-top: $spacer * 25;
  }

  > * {
    flex: 0 1 auto;
  }

  ul {
    flex-grow: 1;
    li {
      font-size: em(20);
      max-width: 25em;;
    }
  }

  .v-image {
    max-width: 60vw;
  }
}

.feats.d-flex:nth-child(odd) {
  flex-direction: row-reverse;
  justify-content: start;
}
@include media-breakpoint-up(lg) {
  ul {
    min-width: 30em;
    margin-right: 2em;
  }
  .feats.d-flex:nth-child(odd) ul {
    margin-left: 2em;
    margin-right: auto;
  }
}
</style>
