<template>
  <div>
    <VContainer class="fp__top-section pa-0 mx-auto" fluid
      :class="{ 'd-flex flex-column': $vuetify.breakpoint.mdAndDown, 'd-grid container narrow': $vuetify.breakpoint.lgAndUp }">

      <VSheet color="grey lighten-5"
        class="fp__banner font-nunito no-shadow  y-0"
        :class="{ 'text-center container container--padded narrow': $vuetify.breakpoint.mdAndDown }">
        <p class="subtitle">{{ topSection.subtitle }}</p>
        <p class="b_title" v-html="topSection.title_1"></p>
        <p class="text mb-12" v-html="topSection.text"></p>

        <p class="text2 mb-15">{{ topSection.text2 }}</p>
      </VSheet>

      <About :content="aboutSection" class="fp__about px-0" :class="{ 'container container--padded narrow': $vuetify.breakpoint.mdAndDown }"
        :site-name="siteName"></About>

      <CloudFeatures class="fp__cloud-features container container--padded narrow px-0 pt-0"
        v-bind="featuresSection" />

      <v-img :src="parallaxSection.img(breakpoint,pixelRatio, true)" :height="$vuetify.breakpoint.lgAndUp ? 477 : 'auto'" contain class="fp__parallax" />

      <ContactForm class="fp__contact" :narrow="false" enable-recaptcha :class="{ 'container container--padded narrow': $vuetify.breakpoint.mdAndDown }"/>
    </VContainer>

    <UniversalFooter class="lp__footer" :social="social" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { pixelRatio } from '../../../utils/display';

import ArrowLink from '../components/atoms/ArrowLink.vue';
import About from '../components/organisms/SectionAbout.vue';
import PrimaryButton from '../components/atoms/PrimaryButton.vue';
import { scrollToElement } from '../../../utils/helpers';
import LandingPageProps from '../config/LandingPageProps';
import ContactForm from '../../../components/ContactForm.vue'
import CloudFeatures from '../components/organisms/SectionCloudFeatures.vue';


export default defineComponent({
  name: 'LandingPage',

  components: {
    ArrowLink,
    About,
    CloudFeatures,
    PrimaryButton,
    ContactForm,
    UniversalFooter: () => import('@/components/UniversalFooter.vue'),
  },

  props: {
    siteName: {
      type: String,
      default: 'FlightScope Cloud',
    },

    topSection: {
      type: Object,
      default: () => ({ ...LandingPageProps.topSection }),
    },

    aboutSection: {
      type: Object,
      default: () => ({ ...LandingPageProps.aboutSection }),
    },

    featuresSection: {
      type: Object,
      default: () => ({ ...LandingPageProps.featuresSection }),
    },

    catSection: {
      type: Object,
      default: () => ({ ...LandingPageProps.catSection }),
    },

    parallaxSection: {
      type: Object,
      default: () => ({ ...LandingPageProps.parallaxSection }),
    },
  },

  data() {
    return {
      social: JSON.parse(process.env.VUE_APP_SOCIAL),
    };
  },

  computed: {
    plansHeaderHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'sm':
          return 120;
      }
      return 80;
    },
    breakpoint() {
      return this.$vuetify.breakpoint.lgAndUp ? 'lg' : this.$vuetify.breakpoint.mdAndUp ? 'md' : 'sm';
    },

    pixelRatio,
  },

  methods: {
    scrollTo: scrollToElement,

    onRegisterClick(e) {
      if (!this.$store.hasModule('servicePlan')) {
        this.$router.push({
          name: 'account.register',
        });
      }
    },

    onContactClick(e) {
      this.scrollTo('contact-form');
    }
  },
});
</script>

<style lang="scss">
@import 'vuetify/src/styles/styles.sass';


.theme--light.v-application.route-landing {
  background-color: #fafafa;
}

.fp__top-section {
  .subtitle {
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }
}
</style>

<style lang="scss" scoped>

.fp__top-section {
  margin-top: 23px;
}

.fp__banner {
  .subtitle {
    color: var(--v-dark-gray-base);
    font-size: em(16);
  }

  .b_title {
    font-size: em(32);
    line-height: 1.42;
    font-weight: 400;
    text-align: center;
    //     margin-bottom: 0;

    &.strong {
      font-weight: 700;
    }

    @include media-breakpoint-up(lg) {
      font-size: em(64);
      text-align: left;
    }
  }

  .text {
    font-size: em(18);
    font-style: normal;
    font-weight: 400;
    line-height: 1.484;
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }

    @include media-breakpoint-up(lg) {
      font-size: em(34);
    }
  }

  .text2 {
    text-align: center;
    font-size: em(14);
    font-style: normal;
    font-weight: 400;
    line-height: 148.9%;

    @include media-breakpoint-up(lg) {
      font-size: em(22);
      text-align: left;
    }
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .fp__top-section {
    margin-top: 0;

    :deep(.subtitle) {
      margin-top: 48px;
      font-size: em(20)
    }
  }

  .fp__top-section.d-grid {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows:
      [topContent] auto
      [topContentEnd bar] 64px
      [barEnd feats] 1fr
      [feat1End feat2] 1fr
      [feat2end feat3] 1fr
      [featsEnd beforeImage] 65px
      [beforeImageEnd img] auto
      [e8 imgOverlap] 67px
      [imgEnd contactForm] auto [sectionEnd]
  }

  .fp__banner {
    grid-area: topContent / 1 / topContentEnd / 7;
  }

  .fp__about {
    grid-area: topContent / 8 / feat1End / 13;
  }

  .fp__cloud-features {
    grid-area: bar / 1 / featsEnd / 13;
  }

  .fp__parallax {
    grid-area: img / 1 / imgEnd / 13;
  }

  .fp__contact {
    grid-area: contactForm / 1 / sectionEnd / span 12;
    width: 100%;
  }

  .lp__footer {
    padding-top: 90px !important;
  }
}
</style>
